import moment from 'moment';
import flatpickr from 'flatpickr';

const cancel_reason_select = document.querySelector('#id_cancel_reason, #id_cancel-cancel_reason');
const cancel_when_select = document.querySelector('#id_cancel_when, #id_cancel-cancel_when');
const cancel_date_input = document.querySelector('#id_cancel_date, #id_cancel-cancel_date');
const cancel_date_wrapper = document.querySelector('#cancel_date, #cancel_cancel-date');
const cancel_summary = document.querySelector('#cancel_summary, #cancel_cancel-summary');

function forceChooseWhenMoving() {
    if (cancel_reason_select) {
        if (cancel_reason_select.value == "moving") {
            cancel_when_select.value = "choose";
            cancel_when_select.classList.add("disabled");
            toggleCancelDateChooserVisibility();
        } else {
            cancel_when_select.classList.remove("disabled");
        }
    }
}

function toggleCancelDateChooserVisibility() {
    if (cancel_when_select && cancel_date_wrapper && cancel_date_input) {
        if (cancel_when_select.value == "choose") {
            cancel_date_wrapper.classList.remove('hide');
            cancel_date_wrapper.classList.add('show');
            cancel_date_input.required = true;
        } else {
            cancel_date_wrapper.classList.remove('show');
            cancel_date_wrapper.classList.add('hide');
            cancel_date_input.required = false;
        }
    }
}

// If they select moving, select "choose" on the when, and disable it.
if (cancel_reason_select && cancel_when_select) {
    cancel_reason_select.addEventListener('change', forceChooseWhenMoving);
    document.addEventListener('DOMContentLoaded', forceChooseWhenMoving);
}

// If "choose" is selected for when, show the date picker
if (cancel_when_select && cancel_date_input && cancel_date_wrapper) {
    cancel_when_select.addEventListener('change', toggleCancelDateChooserVisibility);
    document.addEventListener("DOMContentLoaded", toggleCancelDateChooserVisibility);
}

// Date picker
if (cancel_date_input) {
    const today = moment.utc().hour(0).minute(0).second(0);
    let min = cancel_date_input.min || today.add(1, "days").format("YYYY-MM-DD");
    let max = cancel_date_input.max || today.add(120, "days").format("YYYY-MM-DD");

    flatpickr(cancel_date_input, {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: min,
        maxDate: max,
        disableMobile: true,
    });
}

// Update the cancel summary
if (cancel_reason_select && cancel_when_select && cancel_date_input && cancel_summary) {
    function updateCancelSummary() {
        let summary;
        if (cancel_when_select.value == "now") {
            summary = "Cancellation will take effect <b>immediately.</b> This cannot be undone.";
        } else if (cancel_when_select.value == "choose") {
            if (cancel_date_input.value) {
                const d = moment(cancel_date_input.value).format('MMM Do, YYYY');
                summary = `Cancellation will take effect the morning of <b>${d}</b>`;
            } else {
                summary = "";
            }
        } else if (cancel_when_select.value == "end_of_period") {
            const period_end_date = cancel_summary.dataset.billingPeriodEnd;
            if (period_end_date) {
                summary = `Cancellation will take effect at the end of the billing period which is <b>${period_end_date}</b>`;
            }
        }

        cancel_summary.innerHTML = summary || "";
    }

    cancel_reason_select.addEventListener('change', updateCancelSummary);
    cancel_when_select.addEventListener('change', updateCancelSummary);
    cancel_date_input.addEventListener('change', updateCancelSummary);
}
