
/* Address Auto-complete functions */
let autocomplete;
let ac_form, address_line1, unit, city, state, zip_code, email, email_or_phone, cn_name, cn_passphrase;

let ac_trans = {
    'street_number': {part: 'short_name', target: 'number'},
    'route': {part: 'long_name', target: 'address'},
    'locality': {part: 'long_name', target: 'city'},
    'sublocality_level_1': {part: 'long_name', target: 'sublocality'},
    'neighborhood': {part: 'long_name', target: 'neighborhood'},
    'administrative_area_level_1': {part: 'long_name', target: 'state'},
    'postal_code': {part: 'short_name', target: 'zip_code'}
};

function addressChanged(e) {
    // e.preventDefault();
    const place = autocomplete.getPlace();
    console.debug("address_components", place.address_components);

    const parts = {};
    for (let address_component of place.address_components) {
        const type = address_component.types[0];
        const trans = ac_trans[type];
        if (trans) {
            parts[trans.target] = address_component[trans.part]
        }
    }
    updateForm(parts);
}

function interceptEnterKey(e) {
    if (e.which === 13 || event.keyCode === 13) {
        e.preventDefault();
    }
}

function updateForm(parts) {
    if (parts.number && parts.address) {
        address_line1.value = `${parts.number} ${parts.address}`;
    }
    if (parts.city) {
        city.value = parts.city;
    } else if (parts.sublocality) {
        city.value = parts.sublocality;
    } else if (parts.neighborhood) {
        city.value = parts.neighborhood;
    }
    if (parts.state) {
        for (let i = 0; i < state.options.length; i++) {
            if (state.options[i].text === parts.state) {
                state.selectedIndex = i;
                break;
            }
        }
    }
    if (parts.zip_code) {
        zip_code.value = parts.zip_code;
    }
    setFormFocus();
}

function setFormFocus() {
    if (address_line1 && !address_line1.value) {
        address_line1.focus();
    } else if (unit && !unit.value) {
        unit.focus();
    } else if (city && !city.value) {
        city.focus();
    } else if (state && !state.value) {
        state.focus();
    } else if (zip_code && !zip_code.value) {
        zip_code.focus();
    } else if (email && !email.value) {
        email.focus();
    } else if (email_or_phone && !email_or_phone.value) {
        email_or_phone.focus();
    } else if (cn_name && !cn_name.value) {
        cn_name.focus();
    } else if (cn_passphrase && !cn_passphrase.value) {
        cn_passphrase.focus();
    }
}

export function initAddressAutocomplete() {
    // Fetch references to form elements
    ac_form = document.getElementById('availability_check_form');
    address_line1 = document.getElementById('id_address_line1');
    unit = document.getElementById('id_unit');
    city = document.getElementById('id_city');
    state = document.getElementById('id_state');
    zip_code = document.getElementById('id_zip_code');
    email = document.getElementById('id_email');
    email_or_phone = document.getElementById('id_email_or_phone');
    cn_name = document.getElementById('id_customer_network_name');
    cn_passphrase = document.getElementById('id_customer_network_passphrase');

    if (address_line1) {
        // Set up the autocomplete
        autocomplete = new google.maps.places.Autocomplete(address_line1, {
            'types': ["address"],
            'fields': ["address_components"],
            componentRestrictions: {country: 'us'},
        });
        autocomplete.addListener('place_changed', addressChanged);

        // Override enter key
        address_line1.addEventListener('keypress', interceptEnterKey);

    }

    // Set focus on first empty field
    setFormFocus();
}

/* Auto-generate Customer Network name suggestion based on selected Unit */
function generate_customer_network_name_suggestion(postfix) {
    // We have just a few characters max for a CN.name, so let's be a little intelligent about the pre-filled suggestion
    const postfix_length = postfix.length;
    const field_length = 29;  // This must match CustomerNetwork.name's max_length in the Django Model
    for (let suggestion of ["Andrena Internet Unit ", "Andrena Internet: ", "Andrena: " ]) {
        if (postfix_length <= field_length - suggestion.length) {
            return suggestion + postfix;
        }
    }
    return postfix.substring(0, field_length);
}

document.addEventListener("DOMContentLoaded", function () {
    const unit_input = document.querySelector('#id_unit, #id_cn-unit, #id_customer_network-unit');
    const cn_input = document.querySelector('#id_customer_network_name, #id_cn-name, #id_customer_network-name');
    if (unit_input && cn_input) {
        let last_value = cn_input.value;
        unit_input.addEventListener("change", function (event) {
            if (unit_input.value) {
                let selected = unit_input.options[unit_input.selectedIndex];
                if (!cn_input.value || cn_input.value === last_value) {
                    cn_input.value = generate_customer_network_name_suggestion(selected.text);
                    last_value = cn_input.value;
                }
            } else if (cn_input.value === last_value) {
                cn_input.value = "";
                last_value = cn_input.value;
            }
        });
    }
});
